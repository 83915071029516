// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@use 'js/components/common/icon';\n\n.tu0hKxT5sxvvQyvUXT97 {\n  font-weight: 500;\n\n}\n\n.tu0hKxT5sxvvQyvUXT97[data-size='sm'] {\n    min-width: 28px;\n  }\n\n.tu0hKxT5sxvvQyvUXT97[data-size='md'] {\n    min-width: 32px;\n  }\n\n.tu0hKxT5sxvvQyvUXT97[data-size='lg'] {\n    min-width: 38px;\n  }\n\n.tu0hKxT5sxvvQyvUXT97[data-disabled] {\n      color: var(--button-color);\n      background: var(--button-bg);\n      opacity: 0.5;\n  }\n\n.g95nnQvIGCdFUzX9AZcT[data-position='left'] {\n    margin-right: calc(var(--button-height) * 0.1);\n  }\n\n.g95nnQvIGCdFUzX9AZcT[data-position='right'] {\n    margin-left: calc(var(--button-height) * 0.1);\n  }\n", "",{"version":3,"sources":["webpack://./jsapp/js/theme/kobo/Button.module.css"],"names":[],"mappings":"AAAA,gCAAgC;;AAEhC;EACE,gBAAgB;;AAkBlB;;AAhBE;IACE,eAAe;EACjB;;AACA;IACE,eAAe;EACjB;;AACA;IACE,eAAe;EACjB;;AAEA;MACI,0BAA0B;MAC1B,4BAA4B;MAC5B,YAAY;EAChB;;AAKA;IACE,8CAA8C;EAChD;;AACA;IACE,6CAA6C;EAC/C","sourcesContent":["@use 'js/components/common/icon';\n\n.root {\n  font-weight: 500;\n\n  &[data-size='sm'] {\n    min-width: 28px;\n  }\n  &[data-size='md'] {\n    min-width: 32px;\n  }\n  &[data-size='lg'] {\n    min-width: 38px;\n  }\n\n  &[data-disabled] {\n      color: var(--button-color);\n      background: var(--button-bg);\n      opacity: 0.5;\n  }\n\n}\n\n.section {\n  &[data-position='left'] {\n    margin-right: calc(var(--button-height) * 0.1);\n  }\n  &[data-position='right'] {\n    margin-left: calc(var(--button-height) * 0.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "tu0hKxT5sxvvQyvUXT97",
	"section": "g95nnQvIGCdFUzX9AZcT"
};
export default ___CSS_LOADER_EXPORT___;
