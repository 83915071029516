// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._afkzIoaXS7SxLbEQsDT {\n  color: var(--mantine-color-blue-4);\n}\n\n  ._afkzIoaXS7SxLbEQsDT[data-hovered] {\n    color: var(--mantine-color-blue-5);\n    background-color: transparent;\n  }\n\n  ._afkzIoaXS7SxLbEQsDT[data-variant='danger'] {\n    color: var(--mantine-color-red-5);\n  }\n\n  ._afkzIoaXS7SxLbEQsDT[data-variant='danger'][data-hovered] {\n      color: var(--mantine-color-red-6);\n    }\n", "",{"version":3,"sources":["webpack://./jsapp/js/theme/kobo/Menu.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AAcpC;;EAZE;IACE,kCAAkC;IAClC,6BAA6B;EAC/B;;EAEA;IACE,iCAAiC;EAKnC;;EAHE;MACE,iCAAiC;IACnC","sourcesContent":[".item {\n  color: var(--mantine-color-blue-4);\n\n  &[data-hovered] {\n    color: var(--mantine-color-blue-5);\n    background-color: transparent;\n  }\n\n  &[data-variant='danger'] {\n    color: var(--mantine-color-red-5);\n\n    &[data-hovered] {\n      color: var(--mantine-color-red-6);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "_afkzIoaXS7SxLbEQsDT"
};
export default ___CSS_LOADER_EXPORT___;
